
import { defineComponent } from 'vue'
import { getInsuranceOpenData, InsuranceOpenDto } from '@/api/insurance'
export default defineComponent({
    name: 'InsurancePlanComponent',
    props: ['nodata', 'withoutClosed'],
    data () {
        return {
            data: {} as InsuranceOpenDto[],
            cycleOptions: [
                {
                    key: '月度',
                    value: 'month'
                },
                {
                    key: '季度',
                    value: 'quarter'
                },
                {
                    key: '半年度',
                    value: 'half_year'
                },
                {
                    key: '年度',
                    value: 'year'
                }
            ]
        }
    },
    async created () {
        const result = await getInsuranceOpenData({
            withoutClosed: this.withoutClosed
        })
        this.data = result
    },
    methods: {
        findKeyByValue (value: string) {
            const option = this.cycleOptions.find(option => option.value === value)
            console.log(option, 'option')
            return option ? option.key : null
        }
    }
})
