import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, resolveComponent as _resolveComponent, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-37b96928"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["onClick"]
const _hoisted_2 = ["src"]
const _hoisted_3 = { class: "insurance-title" }
const _hoisted_4 = { class: "insurance-type" }
const _hoisted_5 = { key: 0 }
const _hoisted_6 = { class: "insurance-person" }
const _hoisted_7 = { class: "mini-btn" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_no_data = _resolveComponent("no-data")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.data, (item, index) => {
      return (_openBlock(), _createElementBlock("div", {
        class: _normalizeClass({
            programme: true,
            point: true,
            [item.template]: true
        }),
        style: _normalizeStyle({
            'background-image':
                'url(' +
                require('@/assets/img/insurance/bg-' + item.template + '.png') +
                ')'
        }),
        key: index,
        onClick: ($event: any) => (_ctx.$router.push({ path: '/insurance/detail/' + item.policy_id }))
      }, [
        _createElementVNode("div", null, [
          _createElementVNode("img", {
            class: "insurance-logo",
            src: item?.plan?.logo
          }, null, 8, _hoisted_2)
        ]),
        _createElementVNode("div", null, [
          _createElementVNode("div", _hoisted_3, _toDisplayString(item?.plan?.name), 1),
          _createElementVNode("div", _hoisted_4, [
            _createTextVNode(_toDisplayString(item?.plan?.type) + " ", 1),
            (item.type == 20 && item.cycle != 'month')
              ? (_openBlock(), _createElementBlock("span", _hoisted_5, " (" + _toDisplayString(_ctx.findKeyByValue(item.cycle)) + ") ", 1))
              : _createCommentVNode("", true)
          ]),
          _createElementVNode("div", _hoisted_6, " 当前在保：" + _toDisplayString(item.active_person_count) + "人 ", 1),
          _createElementVNode("button", _hoisted_7, _toDisplayString(item.mark), 1)
        ])
      ], 14, _hoisted_1))
    }), 128)),
    (_ctx.data.length === 0 && !_ctx.nodata)
      ? (_openBlock(), _createBlock(_component_no_data, { key: 0 }))
      : _createCommentVNode("", true)
  ], 64))
}